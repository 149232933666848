import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "../../services/auth.service";

export const authGuard = (state: ActivatedRouteSnapshot) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.checkLogin()) {
    return true;
  }
  if(state.routeConfig?.path) {
    return router.navigate(['/login'],  { queryParams: { returnUrl: state.routeConfig?.path + '?query=' + JSON.stringify(state.queryParams) } });
  }
  // Redirect to the login page
  return router.navigate(['/login']);
};
