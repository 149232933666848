import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from '@environments/environment';
import { AuthService } from './auth.service';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from './session.service';
import { MainService } from './main.service';

@Injectable({
  providedIn: 'root',
})
export class SocketService implements OnDestroy {
  socket: Socket;
  reconnect: EventEmitter<boolean> = new EventEmitter();
  connect: EventEmitter<boolean> = new EventEmitter();
  private socketIdSubject = new BehaviorSubject<string | null>(null);
  public socketId = this.socketIdSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  isConnect = false;
  isConnectSuccess = false;

  constructor(
    private mainService: MainService,
    private toastrServices: ToastrService
  ) {
    this.socket = io(environment.socketAddress, {
      transports: ['websocket', 'polling'],
    });
    this.socket.io.on('reconnect', () => {
      this.authy();
    });

    this.fromEvent('disconnect').subscribe(() => {
      this.isConnect = false;
      this.toastrServices.error(
        this.mainService.getTranslate('common.text.offline')
      );
    });

    this.fromEvent('connected').subscribe((socket_id) => {
      this.socketIdSubject.next(socket_id);
    });
  }

  fromEvent(eventName: string): Observable<any> {
    return Observable.create((observer: any) => {
      this.socket.on(eventName, (res: any) => {
        observer.next(res);
      });
    });
  }

  offEvent(evenName: string) {
    this.socket.off(evenName);
  }

  removeAllListeners() {
    this.socket.removeAllListeners();
  }

  emit(eventName: string, ...args: any[]) {
    this.socket.emit(eventName, ...args);
  }

  authy() {
    this.socket.emit('authy', this.accessToken);
  }

  accessToken?: string;
  connected(accessToken: string) {
    this.accessToken = accessToken;
    this.authy();
    this.fromEvent('authy').subscribe((res) => {
      if (this.isConnectSuccess) {
        // this.toastrServices.success(
        //   this.mainService.getTranslate('common.text.online')
        // );
        this.reconnect.emit(true);
        this.isConnect = true;
      } else {
        this.connect.emit(true);
        this.isConnect = true;
        this.isConnectSuccess = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.socket.disconnect();
    this.socket.close();
  }
}
