import { Pipe, PipeTransform } from '@angular/core';
import { TriggerType } from '../../../../models/trigger';

@Pipe({
  name: 'triggerTypeName',
})
export class TriggerTypeNamePipe implements PipeTransform {
  transform(triggerType?: TriggerType, ...args: unknown[]): unknown {
    switch (triggerType) {
      case 'DEFAULT':
        return 'Default Reply';
      case 'WELCOME':
        return 'Welcome';
      case 'KEYWORD':
        return 'Keywords';
      case 'MESSENGER_REF':
        return 'Ref Link';
      case 'MESSENGER_MENU':
        return 'Messenger Persistent Menu';
      case 'FACEBOOK_COMMENT':
        return 'Comments';
      case 'MESSENGER_CHAT_PLUGIN':
        return 'Messenger chat plugin';
      case 'MESSENGER_MESSAGE_US':
        return 'Messenger message us';
      case 'FACEBOOK_SEND_TO_MESSENGER':
        return 'Facebook send to messenger';
      case 'FACEBOOK_MESSENGER_CHECKBOX':
        return 'Facebook messenger checkbox';
      case 'SESSION':
        return 'Website Livechat';
      case 'BOTAPI':
        return 'Bot API';
      case 'MESSENGER_AD':
        return 'Facebook Ads';
      case 'ZALO_FOLLOW':
        return 'Zalo Follow'
      default:
        return 'Trigger Type';
    }
  }
}
