import { Injectable } from '@angular/core';
import { BaseApiService } from './base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GetResult, IResponseWrapper } from '@core/models';
import { SessionService } from './session.service';
import {
  IInfoRegister,
  IResetPassword,
  IResLogin,
  IResRegister,
} from '@core/models/account';
import { Router } from '@angular/router';
import {BehaviorSubject, distinctUntilChanged, Observable, takeUntil} from 'rxjs';
import { IUser, IUserGoogle } from 'app/models/users';
import { ToastrService } from 'ngx-toastr';
import { MainService } from './main.service';
import { AffiliateService } from './affiliate.service';
import { Cookie } from '@core/cookie';
import {AuthResponse} from "./facebook/models/auth-response";

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseApiService {

  public isLoginSubject = new BehaviorSubject<boolean>(false);
  public isLogin = this.isLoginSubject.asObservable().pipe(distinctUntilChanged());

  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private router: Router,
    private toastrService: ToastrService,
    private mainService: MainService,
    private affiliateService: AffiliateService,
  ) {
    super(http);
  }

  auth = {
    authFacebook: (access_token: string) =>
      this.http.post<IResponseWrapper<any>>(
        this.createUrl(['auth', 'facebook']),
        { accessToken: access_token }
      ),
    authGoogle: (code: string, redirect_uri: string) =>
      this.http.post<IResponseWrapper<IUserGoogle>>(
        this.createUrl(['users', 'googles']),
        { code, redirect_uri }
      ),
    sendFbAccessToken: (access_token: string) =>
      this.http.post(this.createUrl(['users', 'facebooks']), {
        access_token: access_token,
      }),
    getUser: () =>
      this.http.get<IResponseWrapper<IUser>>(this.createUrl(['users', 'me'])),
  };

  checkLogin(): boolean {
    if (this.sessionService.currentAuthUser) {
      this.auth.getUser().subscribe({
        next: (res) => {
          if (res.data) {
            this.sessionService.setUser(res.data);
            this.updateRef();
          }
        },
        error: (err) => {
          if (err.error && err.error.message) {
            this.mainService.handleResErr(err);
          } else {
            this.toastrService.error('Could not get user!');
          }
        },
      });
      this.isLoginSubject.next(true);
      return true;
    }
    this.isLoginSubject.next(false);
    return false;
  }
  private updateRef() {
    const cookieRefValue = Cookie.getCookie('refName');
    if (cookieRefValue) {
      this.affiliateService.refs
        .updateRef({ ref_name: cookieRefValue })
        .subscribe((res) => {
          Cookie.deleteCookie('refName');
        });
    }
  }

  checkVerify(): boolean {
    return !!this.sessionService.registerTokenUser;
  }

  signin(body: string) {
    const url = this.createUrl(['auth', 'login']);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${body}`);

    return this.http.post<IResponseWrapper<IResLogin>>(url, null, {
      headers: headers,
    });
  }

  register(body: IInfoRegister) {
    const url = this.createUrl(['auth', 'register']);
    return this.http.post<IResponseWrapper<IResRegister>>(url, body);
  }

  logout() {
    this.sessionService.userSubject.next(null);
    Cookie.deleteAllCookies();
    this.router.navigateByUrl('/login');
  }

  verify(body: string): Observable<any> {
    const registerToken = this.sessionService.registerTokenUser?.register_token;
    const url = this.createUrl(['auth', 'verify']);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${registerToken}`);

    return this.http.post<any>(url, { code: body }, { headers: headers });
  }

  resend() {
    const registerToken = this.sessionService.registerTokenUser?.register_token;
    const url = this.createUrl(['auth', 'resend']);
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${registerToken}`);

    return this.http.post<any>(url, null, { headers: headers });
  }

  forgotPassword(email: string) {
    const url = this.createUrl(['auth', 'request-reset-password']);
    return this.http.post<any>(url, { email: email });
  }

  resetPassword(body: IResetPassword) {
    const url = this.createUrl(['auth', 'reset-password']);
    return this.http.post<any>(url, body);
  }

}
