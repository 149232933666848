export const environment = {
  production: true,
  baseUrl: 'https://smax.ai/api',
  baseUrlAdmin: 'https://admin.smax.ai',
  socketAddress: 'https://smax.ai',
  facebook: {
    APP_ID: '361260004211097',
    CONFIG_ID: '1062301408746631',
  },
  instagram: {
    CONFIG_ID: '1565000144399135',
  },
  whatsapp: {
    CONFIG_ID: '492997066869360',
  },
  google: {
    clientId:
      '524470225307-qnmo8s7v9odfrdkuq0c54s695n3udrc7.apps.googleusercontent.com',
    apiKey: 'AIzaSyDFoslgDbWNwPAiumqvw2IcCFmo50BimF4',
  },
  nhanhvn: {
    APP_ID: '73639',
  },
  haravan: {
    ClientID: '544a68e3b625f194db934b8b97430072',
  },
  sapo: {
    ClientID: '3f7ed56b03354939bf5278b1d8df18d1',
  },
  smaxApp: {
    host: 'https://smax.app',
    embeddedFormLink: 'https://smax.app/webviews/ext/',
  },
  host: 'https://smax.ai',
  urlWebview: 'https://webview.smax.ai',
};

const parseURL = new URL(location.href);
// console.log(parseURL)
if (!['smax.ai'].includes(parseURL.hostname)) {
  const serviceAddr = `${parseURL.origin}/api`;
  environment.baseUrl = serviceAddr;
  environment.host = parseURL.origin;
  environment.socketAddress = parseURL.origin;
  if (parseURL.host === 'dev.smax.ai' || parseURL.host?.includes('localhost')) {
    environment.nhanhvn.APP_ID = '73621';
    environment.haravan.ClientID = '11ba626767a3c61bcb729e5196cdf0d4';
    environment.sapo.ClientID = 'c11d4c056148499394d41465d41e19d1';
    environment.smaxApp.host = 'https://dev.smax.app';
    environment.smaxApp.embeddedFormLink = 'https://dev.smax.app/webviews/ext/';
    environment.production = false;
    environment.baseUrlAdmin = 'https://admin-dev.smax.ai';
    environment.facebook.APP_ID = '272235120310622';
    environment.facebook.CONFIG_ID = '1022480056008828';
    environment.instagram.CONFIG_ID = '846337107243443';
    environment.whatsapp.CONFIG_ID = '433514079740332';
    environment.google.clientId =
      '524470225307-qnmo8s7v9odfrdkuq0c54s695n3udrc7.apps.googleusercontent.com';
    environment.google.apiKey = 'AIzaSyDFoslgDbWNwPAiumqvw2IcCFmo50BimF4';
    environment.urlWebview = 'https://dev-webview.smax.ai';
    // environment.urlWebview = 'https://72f1-14-229-225-212.ngrok-free.app';
  }
}
// localhost
// dev.smax.ai => dev
// beta.smax.ai => beta === prod
// smax.ai
