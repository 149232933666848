import { Component } from '@angular/core';

@Component({
  selector: 'app-page-error-server',
  templateUrl: './page-error-server.component.html',
  styleUrls: ['./page-error-server.component.scss']
})
export class PageErrorServerComponent {

}
