import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Subject } from 'rxjs';
import { BaseApiService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import {IBroadCast, ICardConfigUserFilter, IGetBroadcastStatistics} from 'app/models/broadcast';
import { IResponseWrapper } from '@core/models';
import { tap } from 'rxjs/operators';
import {IGetSequenceStatistics, ISequence} from "../../models/sequences";

@Injectable({
  providedIn: 'root',
})
export class BroadCastService extends BaseApiService {
  public listBroadCastSubject = new BehaviorSubject<IBroadCast[]>([]);
  public listBroadcast = this.listBroadCastSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public activeBroadCastSubject = new BehaviorSubject<IBroadCast | null>(null);
  public activeBroadCast = this.activeBroadCastSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public selectedChannelIdSubject = new BehaviorSubject('');
  public selectedChannelId = this.selectedChannelIdSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  private updateEventSource = new Subject<void>();
  updateEvent$ = this.updateEventSource.asObservable();

  constructor(private http: HttpClient) {
    super(http);
  }

  emitUpdateEvent() {
    this.updateEventSource.next();
  }

  getAll(bizAlias: string) {
    return this.http
      .get<IResponseWrapper<IBroadCast[]>>(
        this.createUrl(['bizs', bizAlias, 'broadcasts?page=1&limit=9999'])
      )
      .pipe(
        tap((res) => {
          if(res.data) {
            this.getStatistic(bizAlias, res.data);
          }
        })
      );
  }

  setSelectedIdChannel(id: string) {
    this.selectedChannelIdSubject.next(id);
  }

  getByPram(bizAlias: string, param: any) {
    return this.http
      .get<IResponseWrapper<IBroadCast[]>>(
        this.createUrl(['bizs', bizAlias, 'broadcasts?page=1&limit=9999']),
        { params: this.createParams(param) }
      )
      .pipe(
        tap((res) => {
          this.setListBroadCast(res.data);
        })
      );
  }

  delete(bizAlias: string, id: string) {
    return this.http
      .delete<IResponseWrapper<IBroadCast>>(
        this.createUrl(['bizs', bizAlias, 'broadcasts', id])
      )
      .pipe(
        tap((res) => {
          this.removeEntity(this.listBroadCastSubject, id);
          if (this.activeBroadCastSubject.getValue()?.id === id) {
            this.activeBroadCastSubject.next(null);
          }
        })
      );
  }

  create(bizAlias: string, body: Partial<IBroadCast>) {
    return this.http
      .post<IResponseWrapper<IBroadCast>>(
        this.createUrl(['bizs', bizAlias, 'broadcasts']),
        body
      )
      .pipe(
        tap((res) => {
          this.listBroadCastSubject.next([
            ...this.listBroadCastSubject.getValue(),
            { ...res.data },
          ]);
        })
      );
  }

  update(bizAlias: string, id: string, body: Partial<IBroadCast>) {
    return this.http
      .put<IResponseWrapper<IBroadCast>>(
        this.createUrl(['bizs', bizAlias, 'broadcasts', id]),
        body
      )
      .pipe(
        tap((res) => {
          this.updateListBroadCast(res.data);
        })
      );
  }

  statistic(bizAlias: string, blockIds: string[], params={}) {
    return this.http.get<IResponseWrapper<IGetBroadcastStatistics[]>>(
      this.createUrl(['bizs', bizAlias, 'statistics', `broadcasts?ids=${blockIds?.join(',')}`]),{
        params: this.createParams(params)
      }
    ).pipe(
      tap((statistics) => {
        if(statistics.data) {

        }
      })
    );
  }

  getStatistic(alias: string, broadcasts: IBroadCast[]) {
    const idsBlock = broadcasts?.map(o => o.id);

    this.statistic(alias, idsBlock).subscribe({
      next: statistics => {
        if(statistics.data) {
          // @ts-ignore
          const obj = statistics.data.reduce((a, v) => ({ ...a, [v.broadcast_id]: v}), {});
          let listB= broadcasts?.map(o => {
            return {
              ...o,
              statistics: (o.id in obj) && obj[o.id]
                ? obj[o.id] : o?.statistics
                  ? o?.statistics : {customers: 0, success: 0, failures: 0} as IGetSequenceStatistics
            }
          });
          this.listBroadCastSubject.next(listB);
        }
      },
      error: err => {
        this.listBroadCastSubject.next(broadcasts);
      }
    });
  }

  setListBroadCast(broadCast: IBroadCast[]) {
    this.listBroadCastSubject.next(broadCast);
  }

  updateListBroadCast(UpdateBroadCast: IBroadCast) {
    this.updateEntity(this.listBroadCastSubject, UpdateBroadCast);
  }

  setActive(broadCast: IBroadCast | null) {
    this.activeBroadCastSubject.next(broadCast);
  }

  countFilter(bizAlias: string, body: Partial<ICardConfigUserFilter>) {
    return this.http.post<IResponseWrapper<any>>(
      this.createUrl(['bizs', bizAlias, 'customers', 'count']),
      body
    );
  }

  getEntityBroadCast(id_b: string | undefined | null) {
    const data = this.listBroadCastSubject.getValue();
    const index = data.findIndex((o: any) => o.id == id_b);
    if (index !== -1) return data[index];
    else return null;
  }

  getAttr(bizAlias: string, attr: string, page_pids: string[]) {
    const param = {
      attr: attr,
      page_pids: page_pids ? page_pids : [],
      limit: 9999,
    };
    return this.http.get<IResponseWrapper<string[]>>(
      this.createUrl(['bizs', bizAlias, 'customers', 'attr']),
      { params: this.createParams(param) }
    );
  }
}
