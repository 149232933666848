import { inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import {Observable, of, tap, throwError} from 'rxjs';
import { SessionService } from '../../services/session.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import {CatchingInterceptorService} from "../../services/app/catching-interceptor.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  settimeouts: any[] = [];

  constructor(
    private sessionService: SessionService,
    private router: Router,
    private toastr: ToastrService,
    private auth: AuthService,
    private cacheService: CatchingInterceptorService
  ) {}
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const accessToken = this.sessionService.accessToken;
    let headers = request.headers;
    const requestOption: any = {};
    requestOption.setHeaders = {};

    if (accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
          'ngrok-skip-browser-warning': 'true',
        },
      });
      requestOption.setHeaders.Authorization = `Bearer ${accessToken}`;
    }

    // Chỉ cache các request GET
    if (request.method === 'GET') {

      // Kiểm tra cache
      const cachedResponse = this.cacheService.getCache(request);

      // Kiểm tra nếu cache đã hết hạn
      if (!this.cacheService.isCacheExpired(request) && cachedResponse) {
        return of(cachedResponse);  // Trả về cache nếu còn trong thời gian TTL
      }

      // if (cachedResponse) {
      //   console.log('Returning cached response for:', request.url);
      //   return of(cachedResponse);  // Trả về cache nếu còn trong thời gian TTL
      // }
    }

    // @ts-ignore
    return next.handle(request).pipe(
      tap(response => {
        if (response instanceof HttpResponse) {
          if (request.method === 'GET') {
            this.cacheService.setCache(request, response); // Lưu response vào cache
          }
        }
      }),
      catchError((response, retry) => {
        if (response instanceof HttpErrorResponse) {
          if (
            response &&
            [403].includes(response.status) &&
            response.error &&
            response.error.error &&
            response.error.error.name === 'DontSupportVersion'
          ) {
            let text = 'You do not have permission';
            if (response.error) {
              if (response.error.error && response.error.error.message) {
                text = response.error.error.message;
              }
              if (response.error.message) {
                text = response.error.message;
              }
            } else if (typeof response.error === 'string') {
              text = response.error;
            }
            this.toastr.error(text);
            if (this.settimeouts) {
              this.settimeouts.map((a) => clearTimeout(a));
              this.settimeouts = [];
            }
            this.settimeouts.push(
              setTimeout(() => {
                this.toastr.error(
                  'Bạn chưa nhấn Ctrl + F5, hệ thống sẽ tự động reload tạm thời để cập nhật bản mới nhất.',
                  '',
                  { timeOut: 5000 }
                );
                this.settimeouts.push(
                  setTimeout(() => {
                    location.reload();
                  }, 5000)
                );
              }, 10000)
            );
          }
          if (response.status === 401) {
            this.auth.logout();
            // this.router.navigate(['./server-error']);
          }
          if (response.status === 500) {
            // this.router.navigate(['./server-error']);
          }
        }
        return throwError(response);
      })
    );
  }
}
