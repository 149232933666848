import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IPassword } from '@core/models/account';
import {BaseApiService} from "./base.service";
import {IResponseWrapper} from "@core/models";

@Injectable({
  providedIn: 'root',
})
export class UserService  extends BaseApiService {
  constructor(private http: HttpClient) {
    super(http);
  }

  update(body: any): Observable<any> {
    return this.http.put(this.createUrl(['users']), body);
  }

  changePassword(body: IPassword) {
    return this.http.post<any>(this.createUrl(['auth', 'change-password']), body);
  }


  user_device = {
    create: (body: any) => this.http.post<IResponseWrapper<any>>(this.createUrl(['user', 'devices']), body)
  }

}
