import {inject} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {SessionService} from "../../services/session.service";
import {Cookie} from "@core/cookie";

export const resetPwGuard = () => {
  const authService = inject(AuthService);
  const sessionService = inject(SessionService);

  if (authService.checkLogin()) {
    sessionService.userSubject.next(null);
    Cookie.deleteAllCookies();
    return true;
  }

  // Redirect to the login page
  return true;
};
