import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TokenInterceptor } from '@core/interceptors/token.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { SmaxProgressBarModule } from '@shared/ui/smax-progress-bar/smax-progress-bar.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ErrorInterceptor } from '@core/interceptors/error.interceptor';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CommonModule } from '@angular/common';
import { FormsModule, SelectControlValueAccessor } from '@angular/forms';
import { TriggerTypeNamePipe } from '@page/biz/biz-shared/pipes/trigger-type-name.pipe';
import { ColorPickerModule } from 'ngx-color-picker';
import { PageErrorNotfoundComponent } from '@page/errors/page-error-notfound/page-error-notfound.component';
import { PageErrorServerComponent } from '@page/errors/page-error-server/page-error-server.component';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { CustomLoaderTranslatesService } from './services/app/custom-loader-translates.service';
import {GlobalErrorHandlerService} from "./services/app/global-error-handler.service";

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        PageErrorNotfoundComponent,
        PageErrorServerComponent,
    ],
    bootstrap: [AppComponent],
    exports: [],
    imports: [
      CommonModule,
      BrowserModule,
      BrowserAnimationsModule,
      AppRoutingModule,
      NgSelectModule,
      FormsModule,
      ColorPickerModule,
      ToastrModule.forRoot({
          timeOut: 3000,
          maxOpened: 2,
      }),
      ModalModule.forRoot(),
      TranslateModule.forRoot({
          loader: {
              provide: TranslateLoader,
              useClass: CustomLoaderTranslatesService,
              deps: [HttpClient],
          },
      }),
      SmaxProgressBarModule,
      NgxMaskDirective],
    providers: [
      provideNgxMask(),
      SelectControlValueAccessor,
      TriggerTypeNamePipe,
      { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
      { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
      provideHttpClient(withInterceptorsFromDi()),
    ]
  })
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
