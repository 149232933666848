import { Injectable } from '@angular/core';
import { BaseApiService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { IResponseWrapper } from '@core/models';
import { BehaviorSubject, Subject, distinctUntilChanged, map } from 'rxjs';
import {ICustomerSequence, IGetSequenceStatistics, ISequence} from '../../models/sequences';
import { Utils } from '@core/utils';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SequencesService extends BaseApiService {

  LIMIT = 1000;
  LIMIT_MAX_ERROR = 5;
  listSequencesTmp: ISequence[] = [];
  isError = 0;
  paramSequences = {
    page: 1,
    limit: this.LIMIT,
    page_pids: []
  }

  public isDraging = false;

  private updateEventSource = new Subject<void>();
  updateEvent$ = this.updateEventSource.asObservable();

  public selectedChannelIdSubject = new BehaviorSubject('');
  public selectedChannelId = this.selectedChannelIdSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public listSequencesSubject = new BehaviorSubject<ISequence[]>([]);
  public sequences = this.listSequencesSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public activeSequencesSubject = new BehaviorSubject<ISequence | null>(null);
  public activeSequence = this.activeSequencesSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  public groupSequencesByGroupId$ = this.listSequencesSubject.pipe(
    map((sequences) => {
      return Utils.groupById(sequences || [], 'group_id');
    })
  );

  constructor(private http: HttpClient) {
    super(http);
  }

  emitUpdateEvent() {
    this.updateEventSource.next();
  }

  getAll(bizAlias: string, param: any, reset: boolean = false) {
    if(reset) {
      this.paramSequences = {page: 1, limit: this.LIMIT, page_pids: param['page_pids'] ? param['page_pids'] : []};
      this.listSequencesSubject.next([]);
      this.listSequencesTmp = [];
      this.isError = 0;
    }
    this.getAllSequence(bizAlias, param)?.subscribe({
      next: res => {
        if(!res.data?.length) {
          this.listSequencesSubject.next(this.listSequencesTmp);
          return;
        }
        else if(res.data.length >= this.paramSequences.limit) {
          this.paramSequences.page = this.paramSequences.page + 1;
          this.getAll(bizAlias, param);
        } else {
          this.listSequencesSubject.next(this.listSequencesTmp);
        }
      },
      error: err => {
        this.isError++;
        if(this.isError > this.LIMIT_MAX_ERROR) return;
        this.paramSequences.page = this.paramSequences.page + 1;
        this.getAll(bizAlias, param);
      }
    })
  }

  getAllSequence(bizAlias: string, param: any) {
    return this.http
        .get<IResponseWrapper<ISequence[]>>(
            this.createUrl(['bizs', bizAlias, `sequences`]),
            { params: this.createParams({...this.paramSequences, ...param}) }
        )
        .pipe(
            tap((res) => {
              if(res.data) {
                this.listSequencesTmp = [...this.listSequencesTmp, ...res.data];
                // this.getStatistic(bizAlias, res.data);
              }
            })
        );
  }

  delete(bizAlias: string, id: string) {
    return this.http
      .delete<IResponseWrapper<ISequence>>(
        this.createUrl(['bizs', bizAlias, 'sequences', id])
      )
      .pipe(
        tap((res) => {
          this.removeEntitySequenceById(id);
          if (this.activeSequencesSubject.getValue()?.id === id) {
            this.activeSequencesSubject.next(null);
          }
        })
      );
  }

  create(bizAlias: string, body: Partial<ISequence>) {
    return this.http
      .post<IResponseWrapper<ISequence>>(
        this.createUrl(['bizs', bizAlias, 'sequences']),
        body
      )
      .pipe(
        tap((res) => {
          this.listSequencesSubject.next([
            ...this.listSequencesSubject.getValue(),
            { ...res.data },
          ]);
        })
      );
  }

  update(bizAlias: string, id: string, body: Partial<ISequence>) {
    return this.http
      .put<IResponseWrapper<ISequence>>(
        this.createUrl(['bizs', bizAlias, 'sequences', id]),
        body
      )
      .pipe(
        tap((res) => {
          this.updateListSequences(res.data);
        })
      );
  }

  addCustomerSequence(bizAlias: string, page_pid: string, pid: string, body: Partial<ICustomerSequence>) {
    return this.http.put<IResponseWrapper<ICustomerSequence>>(
      this.createUrl(['bizs',bizAlias,'pages', page_pid, 'customers', pid, 'sequences']), body
    ).pipe(
      tap((res) => {

      })
    )
  }

  statistic(bizAlias: string, blockIds: string[]) {
    return this.http.get<IResponseWrapper<IGetSequenceStatistics[]>>(
      this.createUrl(['bizs', bizAlias, 'statistics', `sequences?ids=${blockIds?.join(',')}`])
    ).pipe(
      tap((statistics) => {
        if(statistics.data) {

        }
      })
    );
  }

  getAllStatisticSequence(alias: string, sequences: ISequence[]) {
    if(!sequences?.length) return;
    const idsSequence = sequences?.map(o => o.id);
    this.statistic(alias, idsSequence).subscribe({
      next: statistics => {
        if(statistics.data) {
          // @ts-ignore
          const obj = statistics.data.reduce((a, v) => ({ ...a, [v.sequence_id]: v}), {});
          let listSequences= this.listSequencesSubject.getValue()?.map(sequence => {
            return {
              ...sequence,
              statistics: (sequence.id in obj) && obj[sequence.id]
                ? obj[sequence.id] : sequence?.statistics
                  ? sequence?.statistics : {customers: 0, success: 0, failures: 0} as IGetSequenceStatistics
            }
          });
          this.listSequencesSubject.next(listSequences);
        }
      },
      error: err => {
        this.listSequencesSubject.next(sequences);
      }
    });
  }


  removeEntitySequenceById(sequences_id: string) {
    const listSequences = this.listSequencesSubject.getValue();
    const index = listSequences.findIndex((o) => o.id === sequences_id);
    if (index !== -1) {
      listSequences.splice(index, 1);
      this.listSequencesSubject.next(listSequences);
    }
  }

  setListSequences(sequences: ISequence[]) {
    this.listSequencesSubject.next(sequences);
  }

  updateListSequences(UpdateSequences: ISequence) {
    const updatedListSequences = this.listSequencesSubject
      .getValue()
      .map((sequences) => {
        if (sequences.id === UpdateSequences.id) {
          return UpdateSequences;
        }
        return sequences;
      });
    this.listSequencesSubject.next(updatedListSequences);
  }

  addListSequences(Sequences: ISequence) {
    const updatedListSequences = this.listSequencesSubject
      .getValue()
      .concat(Sequences);
    this.listSequencesSubject.next(updatedListSequences);
  }

  setActive(sequence: ISequence | null) {
    this.activeSequencesSubject.next(sequence);
  }

  setSelectedIdChannel(id: string) {
    this.selectedChannelIdSubject.next(id);
  }

  getEntitySequenceById(id_s: string | undefined) {
    const data = this.listSequencesSubject.getValue();
    const index = data.findIndex((o) => o.id == id_s);
    if (index !== -1) return data[index];
    else return undefined;
  }
}
