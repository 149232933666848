import { Injectable } from '@angular/core';
import { BaseApiService } from './base.service';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {BehaviorSubject, Observable, tap} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MainService extends BaseApiService {
  constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    httpClient: HttpClient
  ) {
    super(httpClient);
  }

  getTranslate(key: string | string[], param?: any) {
    let translateText = '';
    this.translate.get(key, param).subscribe((res) => {
      translateText = res;
    });
    return translateText;
  }

  getToastSuccess(type: 'created' | 'updated' | 'deleted' | 'add' | 'clone' | 'copy') {
    let toastInfo = '';
    switch (type) {
      case "updated":
        toastInfo = this.getTranslate('confirm.message.update_success');break;
      case "created":
        toastInfo =  this.getTranslate('confirm.message.created_success');break;
      case "add":
        toastInfo =  this.getTranslate('confirm.message.add_success');break;
      case "deleted":
        toastInfo =  this.getTranslate('confirm.message.delete_success');break;
      case "clone":
        toastInfo =  this.getTranslate('confirm.message.clone_success');break;
      case "copy":
        toastInfo =  this.getTranslate('confirm.message.copy_success');break;
      default:
        toastInfo =  this.getTranslate('confirm.message.update_success');break;
    }
    this.toastr.success(toastInfo);
  }

  // @ts-ignore
  handleResErr(response: HttpErrorResponse) {
    if (!response) return;
    if (typeof response === 'string') return this.toastr.error(response);
    if ([400, 404, 409].includes(response.status)) {
      let text = '';
      if (response.error && typeof response.error === 'object') {
        if (response.error?.message) {
          text = response.error?.message;
          this.toastr.error(text);
        } else {
          text = response.error?.subStatusText || response.error?.statusText;
          this.toastr.error(
            this.getTranslate(`error.message.${text.toLowerCase()}`) || text
          );
        }
      } else if (typeof response.error === 'string') {
        text = response.error;
        this.toastr.error(text);
      }
    } else if ([401].includes(response.status)) {
      this.toastr.error(this.getTranslate('error.message.unauthorized_error'));
      this.router.navigate(['/']);
    } else if ([403].includes(response.status)) {
      let text = this.getTranslate('error.message.do_not_have_permission');

      if (response.error && typeof response.error === 'object') {
        if (response.error?.message) {
          text = response.error?.message;
          // this.toastr.error(
          //   this.getTranslate(`error.message.${text.toLowerCase()}`) || text
          // );
          this.toastr.error(text);
        } else {
          text = response.error?.subStatusText || response.error?.statusText;
          this.toastr.error(
            this.getTranslate(`error.message.${text.toLowerCase()}`) || text
          );
        }
      } else if (typeof response.error === 'string') {
        text = response.error;
        this.toastr.error(text);
      }
    } else if (response.status === 0) {
      this.toastr.error(
        this.getTranslate('error.message.unable_connect_server')
      );
    } else {
      this.toastr.error(
        response?.error?.message ||
          this.getTranslate('error.message.something_wrong')
      );
    }
  }

  getImageSize(url: string): Observable<number> {
    return new Observable(observer => {
      this.httpClient.head(url, { observe: 'response', responseType: 'blob' }).subscribe(
        (response: HttpResponse<any>) => {
          const contentLength = response.headers.get('Content-Length');
          if (contentLength) {
            observer.next(parseInt(contentLength, 10));
            observer.complete();
          } else {
            observer.error('Content-Length header is missing');
          }
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

  getVideoSize(url: string): Observable<number> {
    return new Observable(observer => {
      this.httpClient.head(url, { observe: 'response' }).subscribe(
        (response: HttpResponse<any>) => {
          const contentLength = response.headers.get('Content-Length');
          if (contentLength) {
            observer.next(parseInt(contentLength, 10));
            observer.complete();
          } else {
            observer.error('Content-Length header is missing');
          }
        },
        error => {
          observer.error(error);
        }
      );
    });
  }

}
