import { IPostFb, PAGE_PLATFORM_ENUM } from './channel';
import { IUser } from './users';
import { FOLLOWUP_IF_CUSTOMER_RESPONSE_TYPE_ENUM } from './follow-ups';

export interface IBiz {
  id: string;
  picture?: string;
  author?: IUser;
  author_id?: string;
  name?: string;
  industry?: string;
  phone?: string;
  email?: string;
  alias: string;
  companySize: string;
  token?: string;
  website: string;
  timezone?: string;
  address?: string;
  api_token?: string;
  created_at?: Date;
  updated_at?: Date;
  export?: IBizExport;
  users: IBizUser[];
  page_pids?: string[];
  user?: IBizUser; // currentUser, người gọi đang gọi api
  order?: IBizOrder;
  active?: boolean;
  config?: {
    noti?: {
      token_error?: boolean;
    };
  };
  roles?: IBizUserRole[];
  user_roles?: IBizUserRole;
  modules?: IBizModule[];
  subcription?: any;
  payment?: any;
  whitelisted_domains?: string[];
  distribution?: IBizDistribution;
  mark_read?: string;
  type?: 'CHAT' | 'BOT' | 'ADS' | 'REMARKETING';
  top_menu_items?: string[];
  top_menu_mode?: 'icon_text' | 'icon' | 'text';
}

export enum TOP_MENU_ENUM {
  dashboard = 'dashboard',
  chats = 'chats',
  bot_auto = 'bot_auto',
  contacts = 'contacts',
  analytics = 'analytics',
  settings = 'settings',
  products = 'products',
  meta_ads = 'meta-ads',
  follow_ups = 'follow-ups',
  messenger_topics = 'messenger-topics',
  meta_catalogs = 'meta-catalogs',
  meta_capis = 'meta-capis',
  custom_images = 'custom-images',
  lucky_wheels = 'lucky-wheels',
  email_builders = 'email-builders',
  orders_management = 'orders-management',
  tables = 'tables',
  forms = 'forms',
  open_gifts = 'open-gifts',
  shops = 'shops',
  floating_contact_buttons = 'floating-contact-buttons',
  posts = 'posts',
  payment_hub = 'payment-hub',
  tickets = 'tickets',
  table_ranking = 'table-ranking',
  quizzes = 'quizzes',
  tasks = 'tasks',
  lead_management = 'lead-management',
  genai = 'genai',
  sms = 'sms',
  puzzle_game = 'puzzle-game',
  coupon = 'coupon',
  virtual_try_on = 'virtual-try-on'
}

export interface IBizLog {
  type?: 'ad' | 'lead' | 'order' | 'followup';
  platform?: `${PAGE_PLATFORM_ENUM}`;
  user_id?: string;
  user_name?: string;
  customer_name?: string;
  customer_pid?: string;
  customer_picture?: string;
  page_pid?: string;
  page_name?: string;
  page_picture?: string;
  followup_id?: string;
  followup_status?: string;
  followup_start_time?: Date;
  followup_end_time?: Date;
  followup_step_id?: string;
  followup_step_time?: Date;
  created_at?: Date;
  followup_customer_response: `${FOLLOWUP_IF_CUSTOMER_RESPONSE_TYPE_ENUM}`;
  followup_log_type?:
    | 'add'
    | 'start'
    | 'step'
    | 'send'
    | 'stop'
    | 'pause'
    | 'continue'
    | 'end'
    | 'customer_response'
    | 'success'
    | 'fail'; // add, start, step, send, stop, pause, continue, end, customer_response,
  followup_log_note?: string;
}

export interface IFollowUpLogsNote {
  success_phone: string;
  success_email: string;
  customer_keyword: string;
  page_keyword: string;
  order_create: true;
  end: true;
}

export interface IBizModule {
  alias?: string;
  added_time?: Date;
  added_by?: string;
}

export interface IBizOrder {
  cards?: number;
  code?: string;
  customers?: number;
  id?: string;
  members?: number;
  months?: number;
  pages?: number;
  type?: 'FREE' | 'PRO' | 'ENTERPRISE';
  time_end?: string;
  time_start?: string;
  used_cards?: number;
  total_customers?: number;
}

export interface ITypeExceedsPackage {
  type: 'WARNING' | 'ERROR' | 'NONE';
  exceed_value: any;
  unit: string;
  value: 'customer' | 'send_card' | 'time';
}

export interface IBizUser {
  id?: string;
  name?: string;
  email?: string;
  off_distribution_type?: '15m' | '1h' | '8h' | '24h' | 'off' | 'on';
  off_distribution_to?: Date;
  picture?: string;
  active?: boolean;
  role?: 'ADMIN' | 'MEMBER';
  role_biz?: 'ADMIN' | 'MEMBER';
  phone?: string, //new
  zaloweb_pid?: string,//new
  employee_code?: string,//new
  member_role_ids?: string[];
  page_pid_notis?: string[];
  notis?: {
    page_pid?: string;
    type?: 'alway_on' | 'on' | 'off';
  }[];
}

export interface IBizUserRole {
  id?: string; // uuid
  name?: string;
  picture?: string;
  desc?: string;
  dashboard?: {
    status?: boolean;
  };
  livechat?: {
    status?: boolean;
    chat_leaders?: string[]; // pid[]
    chat_supporter1s?: string[]; // pid[]
    chat_supporter2s?: string[]; // pid[]
  };
  chatbot?: {
    status?: boolean;
    bot_setup?: boolean;
    bot_preview?: boolean;
  };
  analytic?: {
    status?: boolean;
  };
  contact?: {
    status?: boolean;
  };
  setting?: {
    status?: boolean;
    business?: boolean;
    channel?: boolean;
    team?: boolean;
    integration?: boolean;
    webview_domain?: boolean;
    attribute?: boolean;
    block_template?: boolean;
    tag?: boolean;
    message_template?: boolean;
    api?: boolean;
    package?: boolean;
    upgrade?: boolean;
    log?: boolean;
  };
  log?: {
    status?: boolean;
  };
  extend_module?: {
    status?: boolean;
  };
  page_pids?: string[];
  apply_all?: boolean;
  active?: boolean;
}

export enum IndustryTypeEnum {
  retail_ecommerce = 'retail_ecommerce',
  beauty_wellness = 'beauty_wellness',
  tourism = 'tourism',
  education = 'education',
  financial_services = 'financial_services',
  software_technology = 'software_technology',
  real_estate = 'real_estate',
  automotive = 'automotive',
  art_entertainment = 'art_entertainment',
  agency_consulting = 'agency_consulting',
  legal_services = 'legal_services',
  government_nonprofit = 'government_nonprofit',
  blog = 'blog',
  it = 'IT',
}

export enum MARK_READ_ENUM {
  ALL = 'all',
  SMAXAI = 'smaxai',
  OTHER = 'other',
  NONE = 'none',
}

export const IndustryTypeStyle: any = {
  retail_ecommerce: {
    name: 'Retail & E-commerce',
    color: '#9333ea',
    bg_color: '#f3e8ff',
  },
  beauty_wellness: {
    name: 'Retail & E-commerce',
    color: '#db2777',
    bg_color: '#fce7f3',
  },
  tourism: {
    name: 'Tourism',
    color: '#0ea5e9',
    bg_color: '#e0f2fe',
  },
  education: {
    name: 'Education',
    color: '#0891b2',
    bg_color: '#ecfeff',
  },
  financial_services: {
    name: 'Financial Services',
    color: '#6d28d9',
    bg_color: '#ede9fe',
  },
  software_technology: {
    name: 'Software & Technology',
    color: '#2563eb',
    bg_color: '#dbeafe',
  },
  real_estate: {
    name: 'Real Estate',
    color: '#f97316',
    bg_color: '#fff7ed',
  },
  automotive: {
    name: 'Automotive',
    color: '#ef4444',
    bg_color: '#fef2f2',
  },
  art_entertainment: {
    name: 'Art Entertainment',
    color: '#10b981',
    bg_color: '#ecfdf5',
  },
  agency_consulting: {
    name: 'Agency Consulting',
    color: '#d97706',
    bg_color: '#fffbeb',
  },
  legal_services: {
    name: 'Legal Services',
    color: '#e11d48',
    bg_color: '#fff1f2',
  },
  government_nonprofit: {
    name: 'Government & Nonprofit',
    color: '#0d9488',
    bg_color: '#f0fdfa',
  },
  blog: {
    name: 'Blog',
    color: '#eab308',
    bg_color: '#fefce8',
  },
  it: {
    name: 'IT',
    color: '#4338ca',
    bg_color: '#eef2ff',
  },
};

export interface IBizDistribution {
  active?: boolean;
  user_can_view_none_thread?: boolean; // nhân viên có thể view thread chưa chia
  time?: IBizDistributionTime;
  by_obj?: 'STAFF_ALL' | 'STAFF_ONLINE' | 'STAFF_READ' | 'POST' | 'TYPE';
  priority_for_last_user?: boolean;
  min_staff?: number; // Số nhân viên online ít nhất để áp có thể chia. Tool chia lại bỏ qua hội thoại này
  retry_if_no_reply?: IBizDistributionReplyIfNoAction;
  retry_if_no_read?: IBizDistributionReplyIfNoAction;
  posts?: IBizDistributionPost[];
  inboxs?: string[];
  comments?: string[];
}
export interface IBizDistributionTime {
  all: boolean;
  mon: IBizDistributionTimeDay;
  tue: IBizDistributionTimeDay;
  wed: IBizDistributionTimeDay;
  thu: IBizDistributionTimeDay;
  fri: IBizDistributionTimeDay;
  sat: IBizDistributionTimeDay;
  sun: IBizDistributionTimeDay;
}
export interface IBizDistributionReplyIfNoAction {
  active: boolean;
  delay: number;
  for_new_customer:
    | 'REPLACE_SUPPORTER_1'
    | 'REMOVE_SUPPORTER_1'
    | 'ADD_SUPPORTER_2';
  for_old_customer:
    | 'REPLACE_SUPPORTER_1'
    | 'REMOVE_SUPPORTER_1'
    | 'ADD_SUPPORTER_2';
}
export interface IBizDistributionTimeDay {
  active?: boolean;
  from_1?: number;
  to_1?: number;
  from_2?: number;
  to_2?: number;
}
export interface IBizDistributionPost {
  match?: 'ID' | 'HASHTAG';
  values?: string[];
  users?: string[];
  posts?: IPostFb[];
  page_pid?: string;
}

export interface IBizExport {
  customer?: {
    file: string;
    time: Date;
  };
}
