import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, Observable } from 'rxjs';
import { IUser } from '../models/users';

const TOKEN_KEY = 'smaxai_token';
const REGISTER_TOKEN_KEY = 'smaxai_register_token';
const PROFILE_KEY = 'smaxai_user';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public currentUserSubject = new BehaviorSubject<IUser | null>(null);
  public currentUser = this.currentUserSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  userSubject = new BehaviorSubject(this.getCredentialsFromLocal());
  regiterUserSubject = new BehaviorSubject(this.getRegisterTokenFromLocal());
  currentUserObs$: Observable<any>;

  get accessToken() {
    return this.currentAuthUser?.access_token ?? null;
  }

  get currentAuthUser() {
    return this.userSubject.value;
  }

  get registerTokenUser() {
    return this.regiterUserSubject.value;
  }

  constructor() {
    this.currentUserObs$ = this.userSubject.asObservable();

    this.currentUserObs$.subscribe((user) => {
      this.setUser(user);
      if (!user || !user.access_token) {
        localStorage.removeItem(PROFILE_KEY);
        localStorage.removeItem(TOKEN_KEY);
      } else {
        this.setToken(user.access_token);
        this.setUserProfile(JSON.stringify(user));
      }
    });

    this.regiterUserSubject.asObservable().subscribe((data) => {
      if (!data || !data.register_token) {
        localStorage.removeItem(REGISTER_TOKEN_KEY);
      } else {
        localStorage.setItem(REGISTER_TOKEN_KEY, JSON.stringify(data));
      }
    });
  }

  hasPermissionDev() {
    const roles = this.currentUserSubject.getValue()?.roles;
    if(roles?.includes('DEV') || roles?.includes('ADMIN')) return true;
    return false;
  }

  setToken(value: string) {
    localStorage.setItem(TOKEN_KEY, value);
  }

  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  setUserProfile(value: string): void {
    localStorage.setItem(PROFILE_KEY, value);
  }

  getUserProfile(key: string) {
    return localStorage.getItem(PROFILE_KEY) || null;
  }

  setUser(user: IUser) {
    this.currentUserSubject.next(user);
    // console.log(this.currentUser);
  }

  private getCredentialsFromLocal() {
    const credentials = this.getUserProfile(TOKEN_KEY);
    if (credentials) {
      return JSON.parse(credentials);
    } else {
      return null;
    }
  }

  private getRegisterTokenFromLocal() {
    const credentials = localStorage.getItem(REGISTER_TOKEN_KEY) || null;
    if (credentials) {
      return JSON.parse(credentials);
    } else {
      return null;
    }
  }
}
