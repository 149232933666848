<div class="container-fluid page-error-container">
  <div class="d-flex gap-2 align-items-center justify-content-center page-error-header">
    <img src="assets/icon/new_logo_icon_text.svg" height="40">
    <!--    <span class="fs-v18 fw-bold">Prime V</span>-->
  </div>
  <div class="page-error-body container row">
    <div class="col-5 d-flex align-items-center">
      <div class="d-flex flex-column gap-1">
        <span class="fw-bolder fs-v43">Oops...</span>
        <span class=" fs-v32">{{'common.text.server_error' | translate}}</span>
        <span
          class="fw-normal ">{{'common.text.server_error_sub' | translate}}</span>

        <div style="margin-top: 2rem;">
          <button class="btn btn-primary btn-lg" type="button" [routerLink]="['/']">
            <i class="fa-solid fa-arrow-left me-1"></i>
            <span translate>common.button.back_to_home</span>
          </button>
        </div>
      </div>
    </div>
    <div class="col-7 d-flex align-items-center justify-content-center">
      <div class="d-flex flex-column justify-content-center align-items-center gap-2">
        <img src="assets/img/error-500.svg" width="500">
      </div>
    </div>
  </div>
</div>
