import {Injectable} from '@angular/core';
import {BaseApiService} from "../base.service";
import { HttpClient } from "@angular/common/http";
import {ICard, ICardType} from "../../models/card";
import {IResponseWrapper} from "@core/models";
import {BehaviorSubject, distinctUntilChanged, map, combineLatest} from "rxjs";
import {tap} from "rxjs/operators";
import {Utils} from "@core/utils";
import {IBlock} from "../../models/block";
import * as _ from "lodash";

@Injectable({
  providedIn: 'root'
})
export class CardsService extends BaseApiService {

  LIMIT = 1000;
  LIMIT_MAX_ERROR = 5;
  isError = 0;
  paramCards = {
    page: 1,
    limit: this.LIMIT
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  public listCardsTypeSubject = new BehaviorSubject<ICardType[]>([]);
  public listCardsType = this.listCardsTypeSubject.asObservable().pipe(distinctUntilChanged());

  public cardsTypeActiveSubject = new BehaviorSubject<ICardType | null>(null);
  public cardsTypeActive = this.cardsTypeActiveSubject.asObservable().pipe(distinctUntilChanged());

  public listCardsSubject = new BehaviorSubject<ICard<any>[]>([]);
  public listCards = this.listCardsSubject.asObservable().pipe(distinctUntilChanged());

  groupCardsByBlockId$ = combineLatest([
    this.listCardsSubject,
    this.listCardsTypeSubject,
  ]).pipe(
    map(([cards, cardTypes]) => {
      return cards?.map((card) => {
        const cardType = cardTypes?.find(
          (item) => item.key === card.type);
        return {
          ...card,
          name: cardType?.name,
          description: cardType?.desc,
          platform: cardType?.platform
        };
      });
    }),

    map((cards) => {
      // @ts-ignore
      return Utils.groupById(cards, 'block_id');
    })
  );

  card_type = {
    getAll: () => this.http.get<IResponseWrapper<ICardType[]>>(this.createUrl(['card-types'])).pipe(
      tap((res) => {
        this.setListCardTypes(res.data);
      }))
  };

  cards = {
    getAll: (bizAlias: string, reset: boolean = false) => {
      if(reset) {
        this.paramCards = {page: 1, limit: this.LIMIT};
        this.listCardsSubject.next([]);
        this.isError = 0;
      }
      this.getAllCard(bizAlias)?.subscribe({
        next: res => {
          if(!res.data?.length) return;
          else if(res.data.length >= this.paramCards.limit) {
            this.paramCards.page = this.paramCards.page + 1;
            this.cards.getAll(bizAlias);
          }
        },
        error: err => {
          this.isError++;
          if(this.isError > this.LIMIT_MAX_ERROR) return;
          this.paramCards.page = this.paramCards.page + 1;
          this.cards.getAll(bizAlias);
        }
      })
    },
    getById: (bizAlias: string, cardId: string) => this.http.get<ICard<any>[]>(this.createUrl(['bizs', bizAlias, 'cards', cardId])),
    create: (bizAlias: string, body: Partial<ICard<any>>, isTemp = false) => {
      if (isTemp) {
        // @ts-ignore
        this.listCardsSubject.next([...this.listCardsSubject.getValue(), {...body}]);
        return;
      }
      return this.http.post<IResponseWrapper<ICard<any>>>(this.createUrl(['bizs', bizAlias, 'cards']), body).pipe(tap(res => {
        // @ts-ignore
        this.listCardsSubject.next([...this.listCardsSubject.getValue(), {...res.data}]);
      }));
    },
    update: (bizAlias: string, cardId: string, body: any, isTemp = false) => {
      if (isTemp) {
        this.updateEntityCards(cardId, {...body});
        return;
      }
      return this.http.put<IResponseWrapper<ICard<any>>>(
        this.createUrl(['bizs', bizAlias, 'cards', cardId]), body).pipe(tap(res => {
        this.updateEntityCards(cardId, res.data);
      }));
    },
    testAction: (bizAlias: string, cardId: string, body: {action: string, card: any}) => {
      return this.http.post<IResponseWrapper<any>>(this.createUrl(['bizs', bizAlias, 'cards', cardId]), body);
    },
    addSheetInGoogleSheet: (bizAlias: string, cardId: string, body: {action: string, name: string, card: any}) => {
      return this.http.post<IResponseWrapper<any>>(this.createUrl(['bizs', bizAlias, 'cards', cardId]), body);
    },
    delete: (bizAlias: string, cardId: string, isTemp = false) => {
      if (isTemp) {
        this.removeEntityCardById(cardId);
        return;
      }
      return this.http.delete<IResponseWrapper<ICard<any>>>(this.createUrl(['bizs', bizAlias, 'cards', cardId])).pipe(tap(res => {
        this.removeEntityCardById(cardId);
      }));
    }
  }

  getAllCard(bizAlias: string) {
    return this.http.get<IResponseWrapper<ICard<any>[]>>(
        this.createUrl(['bizs', bizAlias, `cards?page=${this.paramCards.page}&limit=${this.paramCards.limit}`])).pipe(
        tap((res) => {
          this.setListCards([...this.listCardsSubject.getValue(), ...res.data]);
        })
    )
  }

  setListCardTypes(cardTypes: ICardType[]) {
    this.listCardsTypeSubject.next(cardTypes.map(o => {
      return {...o, isTemp: false}
    }));
  }

  getEntityCardType(card_type_id: string) {
    let listCardsType = this.listCardsTypeSubject.getValue();
    const index = listCardsType.findIndex(o => o.key === card_type_id);
    return index !== -1 ? listCardsType[index] : null;
  }

  getEntityCard(card_id: string) {
    let listCards = this.listCardsSubject.getValue();
    const index = listCards.findIndex(o => o.id === card_id);
    return index !== -1 ? listCards[index] : null;
  }

  setListCards(cards: ICard<any>[]) {
    this.listCardsSubject.next(cards);
  }

  removeEntityCardById(card_id: string) {
    let listCards = this.listCardsSubject.getValue();
    const index = listCards.findIndex(o => o.id === card_id);

    if (index !== -1) {
      listCards.splice(index, 1);
      this.listCardsSubject.next(listCards);
    }
  }

  updateEntityCards(card_id: string | undefined, value: any) {
    let listCards = this.listCardsSubject.getValue();
    const index = listCards.findIndex(o => o.id === card_id);
    if (index !== -1) {
      listCards[index] = value;
      this.listCardsSubject.next(listCards);
    }
  }

  setCardsTypeActive(card_type: ICardType) {
    this.cardsTypeActiveSubject.next(card_type);
  }

  countContentCardStatistic(cards?: any[]) {
    const arr_ig = ['messenger_block', 'facebook_audience', 'messenger_handover', 'messenger_capi'];
    return cards?.filter(o => !arr_ig?.includes(o.type))?.length || 0;
  }

  cardsMapCardType(): ICard<any>[] {
    return this.listCardsSubject.getValue().map((card) => {
      const cardType = this.listCardsTypeSubject.getValue()?.find(
        (item) => item.key === card.type
      );
      return {
        ...card,
        name: cardType?.name,
        description: cardType?.desc,
        platform: cardType?.platform,
      };
    }) || [];
  }

  handleDataCardInBlock(block: IBlock): IBlock {
    if (!block) return {} as IBlock;

    const listCards = this.listCardsSubject.getValue().map((card) => {
      const cardType = this.listCardsTypeSubject.getValue()?.find(
        (item) => item.key === card.type
      );
      return {
        ...card,
        name: cardType?.name,
        description: cardType?.desc,
        platform: cardType?.platform,
      };
    });
    const cardsOfBlock = Utils.groupById(listCards, 'block_id');
    return {
      ...block,
      statistics: {
        ...block.statistics,
        content_card: this.countContentCardStatistic(cardsOfBlock[block.id] || []),
      },
      cards: cardsOfBlock[block.id]
        ? _.uniqBy(cardsOfBlock[block.id].sort((a, b) => a.weight - b.weight) ?? [], 'id')
        : [],
    };
  }
}
