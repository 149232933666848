import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {AuthService} from "../../services/auth.service";

export const verifyGuard = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.checkVerify()) {
    return true;
  }

  // Redirect to the login page
  return router.navigate(['/login']);
};
