import { IMenu } from '../../models/root';

export const subMenuChatbot: IMenu[] = [
  {
    name: 'layout.menu.bot_flows',
    key: 'triggers',
    router: 'triggers',
    icon: 'menu_trigger',
  },
  {
    name: 'layout.menu.block_library',
    key: 'blocks',
    router: 'blocks',
    icon: 'block_library',
  },
  {
    name: 'layout.menu.sequence',
    key: 'sequences',
    router: 'sequences',
    icon: 'menu_sequence',
  },
  {
    name: 'layout.menu.broadcast',
    key: 'broadcast',
    router: 'broadcast',
    icon: 'broadcast',
  },
  // {
  //   name: 'layout.menu.3rd_events',
  //   key: '3rd_events',
  //   router: '3rd_events',
  //   disabled: true,
  //   icon: 'third_event',
  // },
];
export const subMenuExtend: IMenu[] = [
  {
    name: 'layout.menu.lucky_wheel',
    key: 'lucky-wheels',
    router: 'lucky-wheels',
    icon: 'lucky_wheel',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.open_gift',
    key: 'open-gifts',
    router: 'open-gifts',
    icon: 'open_gift',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.shop',
    key: 'shops',
    router: 'shops',
    icon: 'shop',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.form',
    key: 'forms',
    router: 'forms',
    icon: 'form',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.image_design',
    key: 'custom-images',
    router: 'custom-images',
    icon: 'image_design',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.meta_capi',
    key: 'meta-capis',
    router: 'meta-capis',
    icon: 'meta_capi_menu',
    isModuleExtend: true
  },
  {
    name: 'Floating Contact Button',
    key: 'floating-contact-buttons',
    router: 'floating-contact-buttons',
    icon: 'floating_contact_menu',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.posts',
    key: 'posts',
    router: 'posts',
    icon: 'posts_menu',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.quizzes',
    key: 'quizzes',
    router: 'quizzes',
    icon: 'quizzes', // ko co cung dc
    isModuleExtend: true
  },
  {
    name: 'layout.menu.table_ranking',
    key: 'table-ranking',
    router: 'table-ranking',
    icon: 'table_ranking', // ko co cung dc
    isModuleExtend: true
  },
  {
    name: 'SMS',
    key: 'sms',
    router: 'sms',
    icon: 'menu_esms', // ko co cung dc
    isModuleExtend: true
  },
  {
    name: 'layout.menu.payment_hub',
    key: 'payment-hub',
    router: 'payment-hub',
    icon: 'payment_hub_menu',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.database',
    key: 'tables',
    router: 'tables',
    icon: 'database',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.email_builder',
    key: 'email-builders',
    router: 'email-builders',
    icon: 'email',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.product_manager',
    key: 'products',
    router: 'products',
    icon: 'product',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.order_manager',
    key: 'orders-management',
    router: 'orders-management',
    icon: 'order',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.leads_management',
    key: 'lead-management',
    router: 'lead-management',
    icon: 'phone',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.messenger_topic',
    key: 'messenger-topics',
    router: 'messenger-topics',
    icon: 'messenger_topic',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.sponsored_messages',
    key: 'sponsored-messages',
    router: 'sponsored-messages',
    icon: 'sponsored_messages',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.meta_catalogs',
    key: 'meta-catalogs',
    router: 'meta-catalogs',
    icon: 'catalogs',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.follow_ups',
    key: 'follow-ups',
    router: 'follow-ups',
    icon: 'follow_up',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.ads_management',
    key: 'meta-ads',
    router: 'meta-ads',
    icon: 'meta-ads',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.ticket_support',
    key: 'tickets',
    router: 'tickets',
    icon: 'ticket_menu',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.gen_ai',
    key: 'genai',
    router: 'genai',
    icon: 'genai',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.tasks',
    key: 'tasks',
    router: 'tasks',
    icon: 'tasks',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.puzzle_game',
    key: 'puzzle-game',
    router: 'puzzle-game',
    icon: 'puzzle_game',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.coupon',
    key: 'coupon',
    router: 'coupon',
    icon: 'coupon',
    isModuleExtend: true
  },
  {
    name: 'layout.menu.virtual_try_on',
    key: 'virtual-try-on',
    router: 'virtual-try-on',
    icon: 'virtual_try_on',
    isModuleExtend: true
  },
];
export const subMenuAnalytics: IMenu[] = [
  {
    name: 'layout.menu.customer',
    key: 'customers',
    router: 'analytics/customers',
    icon: 'statistic_customer',
  },
  {
    name: 'layout.menu.messages',
    key: 'messages',
    router: 'analytics/messages',
    icon: 'statistic_message',
  },
  {
    name: 'layout.menu.staf',
    key: 'staff',
    router: 'analytics/staff',
    icon: 'statictis_staff',
  },
  {
    name: 'layout.menu.statistic_orders',
    key: 'orders',
    router: 'analytics/orders',
    icon: 'statictis_staff',
  },
  {
    name: 'layout.menu.tag',
    key: 'tag',
    router: 'analytics/tag',
    icon: 'tag',
  },
];
export const subMenuSettings: IMenu[] = [
  {
    key: 'profile',
    router: 'settings/profile',
    name: 'layout.menu.business',
    icon: 'profile',
    roleKey: 'business',
  },
  {
    key: 'top-menu',
    router: 'settings/top-menu',
    name: 'layout.menu.top_menu',
    icon: 'menu_top_menu',
    roleKey: 'top-menu',
  },
  {
    key: 'channels',
    router: 'settings/channels',
    icon: 'channels',
    name: 'layout.menu.channel',
    roleKey: 'channel',
  },
  {
    key: 'members',
    icon: 'members',
    router: 'settings/members',
    name: 'layout.menu.teams',
    roleKey: 'team',
  },
  {
    key: 'integrations',
    router: 'settings/integrations',
    icon: 'integrations',
    name: 'layout.menu.integration',
    roleKey: 'integration',
  },
  {
    key: 'webview-domains',
    router: 'settings/webview-domains',
    icon: 'webview-domains',
    name: 'layout.menu.webview_domain',
    roleKey: 'webview_domain',
  },
  {
    key: 'attributes',
    router: 'settings/attributes',
    icon: 'attributes',
    name: 'layout.menu.attributes',
    roleKey: 'attribute',
  },
  // {
  //   key: 'block-templates',
  //   router: 'settings/block-templates',
  //   icon: 'block',
  //   name: 'layout.menu.block',
  //   roleKey: 'block_template',
  // },
  {
    key: 'tags',
    router: 'settings/tags',
    icon: 'tags',
    name: 'layout.menu.tag',
    roleKey: 'tag',
  },
  {
    key: 'message-templates',
    router: 'settings/message-templates',
    name: 'layout.menu.message_template',
    roleKey: 'message_template',
  },
  {
    key: 'api',
    router: 'settings/api',
    icon: 'api',
    name: 'layout.menu.api',
    roleKey: 'api',
  },
  {
    key: 'orders',
    router: 'settings/orders',
    icon: 'orders',
    name: 'layout.menu.package',
    roleKey: 'package',
  },
  {
    key: 'upgrades',
    router: 'settings/upgrades',
    icon: 'upgrade',
    name: 'Upgrade',
    roleKey: 'upgrade',
    // isOnlyDev: true,
  },
  {
    name: 'Logs',
    key: 'logs',
    router: 'settings/logs',
    icon: 'menu_logs',
    roleKey: 'log',
  },
];

export const menuList: IMenu[] = [
  {
    name: 'layout.menu.dashboard',
    key: 'dashboard',
    router: 'dashboard',
    icon: 'menu_dashboard',
    nameMenu: 'dashboard',
    roleKey: 'dashboard',
    invisible: true,
    // disabled: true,
  },
  {
    name: 'layout.menu.chats',
    key: 'chats',
    router: 'chats',
    nameMenu: 'chats',
    icon: 'menu_chat',
    roleKey: 'livechat',
  },
  {
    name: 'layout.menu.bots',
    key: 'triggers',
    router: 'triggers',
    nameMenu: 'triggers',
    icon: 'menu_trigger',
    roleKey: 'chatbot',
    subs: subMenuChatbot,
  },
  {
    name: 'layout.menu.contacts',
    key: 'contacts',
    nameMenu: 'contacts',
    router: 'contacts',
    icon: 'menu_contact',
    roleKey: 'contact',
  },
  {
    name: 'layout.menu.analytics',
    key: 'analytics',
    nameMenu: 'analytics',
    router: 'analytics',
    icon: 'menu_analytics',
    roleKey: 'analytic',
    subs: subMenuAnalytics || [],
  },
  // {
  //   name: 'layout.menu.templates',
  //   key: 'templates',
  //   nameMenu: 'templates',
  //   router: 'templates',
  //   icon: 'menu_templates',
  //   roleKey: 'templates',
  // },
  {
    name: 'layout.menu.settings',
    key: 'settings',
    nameMenu: 'settings',
    router: 'settings',
    icon: 'menu_setting',
    roleKey: 'setting',
    subs: subMenuSettings || [],
  },
];

export const otherMenu: IMenu[] = [
  {
    name: 'layout.menu.extend_menu',
    nameMenu: 'extend_menu',
    icon: 'menu_extend_1',
    key: 'lucky-wheels',
    router: 'lucky-wheels',
    subs: subMenuExtend || [],
    roleKey: 'visible',
  },
];

export const KeyMenuSameRole = {
  profile: 'business',
  channels: 'channel',
  members: 'team',
  integrations: 'integration',
  'webview-domains': 'webview_domain',
  attributes: 'attribute',
  'block-templates': 'block_template',
  tags: 'tag',
  'message-templates': 'message_template',
  api: 'api',
  orders: 'package',
  upgrades: 'upgrade',
  triggers: 'chatbot',
  sequences: 'chatbot',
  blocks: 'chatbot',
  broadcasts: 'chatbot',
  contacts: 'contact',
  chats: 'livechat',
  settings: 'setting',
  logs: 'log',
};

export const ListMenuDefaultBot = ['dashboard', 'chats', 'bot_auto', 'contacts', 'analytics'];
export const LisMenuDefaultChat = ['chats', 'contacts', 'bot_auto'];
export const ListMenuDefaultAds = ['chats', 'bot_auto', 'meta_ads'];
export const ListMenuDefaultRemarketing = ['chats', 'bot_auto', 'follow_ups'];
