
export const DEFAULT_BLOCK_KEY = 'DEFAULT_BLOCK';
export const DEFAULT_SCRIPT_KEY = 'DEFAULT_SCRIPT';
export const DEFAULT_TRIGGER_KEY = 'DEFAULT_TRIGGER';

export type DEFAULT_KEY = keyof typeof DEFAULT_KEY_ENUM;

export enum DEFAULT_KEY_ENUM {
    DEFAULT_BLOCK = 'DEFAULT_BLOCK',
    DEFAULT_SCRIPT = 'DEFAULT_SCRIPT',
    DEFAULT_TRIGGER = 'DEFAULT_TRIGGER',
    DEFAULT_SEQUENCE = 'DEFAULT_SEQUENCE'
}