import { Injectable } from '@angular/core';
import { BizService } from "./biz.service";
import { SessionService } from "../session.service";
import {BehaviorSubject, combineLatest, distinctUntilChanged, startWith, Subject} from "rxjs";
import { IMenu } from "../../models/root";
import { MarketplaceService } from "../marketplace.service";
import {KeyMenuSameRole, menuList, otherMenu, subMenuExtend, subMenuSettings} from "@core/constants/menu";
import { Router } from "@angular/router";
import {TOP_MENU_ENUM} from "../../models/biz";
import * as _ from "lodash";

@Injectable({
  providedIn: 'root'
})
export class MenusService {

  public allMenusListSubject = new BehaviorSubject<IMenu[]>([]);
  public allMenusList = this.allMenusListSubject.asObservable().pipe(distinctUntilChanged());

  public menusSubject = new BehaviorSubject<IMenu[] | undefined | null>([]);
  public menus = this.menusSubject.asObservable().pipe(distinctUntilChanged());

  public menuActiveSubject = new BehaviorSubject<IMenu | undefined | null>(null);
  public menuActive = this.menuActiveSubject.asObservable().pipe(distinctUntilChanged());

  private isBackNavigation = false;
  private backNavigationSubject = new Subject<boolean>();

  constructor(
    private bizServices: BizService,
    private sessionService: SessionService,
    private marketplaceService: MarketplaceService,
    private router: Router,
  ) { }

  handelMenu() {
    this.bizServices.currentBiz.subscribe(res => {
      if(res) {
        const listMenuTop = res?.top_menu_items ?? [];

        const menuFilter = [...menuList, ...subMenuExtend]?.filter(menu => {
          if(menu?.key == 'settings') return menu;
          const key = menu?.key == 'triggers' ? TOP_MENU_ENUM.bot_auto : menu?.key;
          return listMenuTop?.includes(key ?? '');
        });

        const newListMenuSort = listMenuTop?.length ? [...menuFilter, ...otherMenu]
          .sort((a, b) => {
            const key_a = a?.key == 'triggers' ? TOP_MENU_ENUM.bot_auto : a?.key;
            const key_b = b?.key == 'triggers' ? TOP_MENU_ENUM.bot_auto : b?.key;
            return listMenuTop.indexOf(this.formatKey(key_a)) - listMenuTop.indexOf(this.formatKey(key_b))
          })
          : [...menuFilter, ...otherMenu];

        this.allMenusListSubject.next([...subMenuExtend, ...menuList]);
        this.setMenusList(newListMenuSort);

      }
    })
    this.handelMenuWithRoles();
    this.handelMenuExtendMenu();
  }



  setBackNavigation(value: boolean) {
    this.isBackNavigation = value;
    this.backNavigationSubject.next(this.isBackNavigation);
  }


  handelMenuWithRoles() {
    combineLatest([
      this.bizServices.currentBizSubject,
      this.bizServices.userRolesBizSubject
    ]).pipe().subscribe(([biz, userRole]) => {
      if (biz && userRole) {
        let arrayIgnore: (string | undefined)[] = [];
        let arraySubIgnore: (string | undefined)[] = [];

        if (!userRole.chatbot?.status
          || (userRole.chatbot?.status && (!userRole?.chatbot.bot_preview && !userRole?.chatbot.bot_setup))) {
          arrayIgnore.push('triggers');
        }
        if (!userRole.contact?.status) arrayIgnore.push('contacts');
        if (!userRole.livechat?.status) arrayIgnore.push('chats');
        if (!userRole.analytic?.status) arrayIgnore.push('analytics');
        if (!userRole.livechat?.status) arrayIgnore.push('chats');
        if (!userRole.dashboard?.status) arrayIgnore.push('dashboard');
        if (!userRole.setting?.status) arrayIgnore.push('settings');
        if (!userRole.extend_module?.status) arrayIgnore.push('extend_module');
        if (!userRole.log?.status && userRole.setting?.status && !userRole.setting?.log && userRole.setting?.status) arraySubIgnore.push('logs');
        if (userRole.setting?.status) {
          if (!userRole.setting?.business) arraySubIgnore.push('profile');
          if (!userRole.setting?.channel) arraySubIgnore.push('channels');
          if (!userRole.setting?.team) arraySubIgnore.push('members');
          if (!userRole.setting?.integration) arraySubIgnore.push('integrations');
          if (!userRole.setting?.webview_domain) arraySubIgnore.push('webview-domains');
          if (!userRole.setting?.attribute) arraySubIgnore.push('attributes');
          if (!userRole.setting?.block_template) arraySubIgnore.push('block-templates');
          if (!userRole.setting?.tag) arraySubIgnore.push('tags');
          if (!userRole.setting?.message_template) arraySubIgnore.push('message-templates');
          if (!userRole.setting?.api) arraySubIgnore.push('api');
          if (!userRole.setting?.package) arraySubIgnore.push('orders');
          if (!userRole.setting?.upgrade) arraySubIgnore.push('upgrades');
          if (!userRole.setting?.log) arraySubIgnore.push('logs');
        }

        const listMenu = this.menusSubject.getValue()?.filter(o => !arrayIgnore?.includes(o?.key))?.map(menu => {
          return menu.key == 'settings' ? {
            ...menu,
            subs: menu?.subs?.filter(s => !arraySubIgnore?.includes(s?.key))
          } : menu;
        });

        const indexMenuSettings = (listMenu || [])?.findIndex(o => o.key == 'settings');
        if(indexMenuSettings > -1) {
          if(listMenu && listMenu[indexMenuSettings]) {
            const list = this.allMenusListSubject.getValue();
            list.push(listMenu[indexMenuSettings]);
            this.allMenusListSubject.next(list);
          }
        }
        if (listMenu) {
          this.setMenusList(listMenu);
        }
      }
    })
  }

  handelMenuExtendMenu() {
    combineLatest([
      this.bizServices.currentBizSubject,
      this.sessionService.currentUserSubject,
      this.marketplaceService.listSysModuleSubject
    ]).pipe().subscribe(([biz, user, listSys]) => {
      if (biz && user && listSys) {
        const isUserRoleDev = this.sessionService.hasPermissionDev();
        const listMenuTop = biz?.top_menu_items ?? [];
        const modulesInBiz = biz?.modules
          ?.filter(o => {
            const findAlias = listSys?.find(s => s?.alias == o?.alias);
            return o?.alias && findAlias;
          })?.map(m => m.alias);


        let listMenu = this.menusSubject.getValue() ?? [];
        const index = listMenu
          ?.findIndex((item) => item.nameMenu === 'extend_menu');
        if (index !== -1 && listMenu) {
          const menuFromBiz = subMenuExtend?.filter(
            (item: any) => {
              return modulesInBiz?.includes(item.key) || listMenuTop?.includes(item.key);
            }
          );
          let menuNew = menuFromBiz;
          if(modulesInBiz) {
            // Tạo map để lưu vị trí của các key trong mảng modulesInBiz
            const orderMap = _.fromPairs(modulesInBiz.map((key, index) => [key, index]));

            // Sử dụng _.sortBy với lodash
            menuNew = _.sortBy(menuFromBiz, (obj) => {
              return _.has(orderMap, obj.key) ? orderMap[obj.key] : Infinity;
            });
          }

          // @ts-ignore
          listMenu[index].subs = menuNew;
          // @ts-ignore
          listMenu[index].key = !listMenu[index].subs!.length ? 'empty-module' : listMenu[index].key;

          const newListMenuSort = listMenuTop?.length
            ? listMenu.sort((a, b) => {
              const key_a = a?.key == 'triggers' ? TOP_MENU_ENUM.bot_auto : a?.key;
              const key_b = b?.key == 'triggers' ? TOP_MENU_ENUM.bot_auto : b?.key;
              return listMenuTop.indexOf(this.formatKey(key_a)) - listMenuTop.indexOf(this.formatKey(key_b))
            })
            : listMenu;
          this.setMenusList(newListMenuSort);
          if(this.menuActiveSubject?.getValue()?.nameMenu == 'extend_menu') {
            this.menuActiveSubject.next({
              ...this.menuActiveSubject.getValue(),
              subs: menuNew
            } as IMenu)
          }

          if(listMenu[index] && index > -1) {
            const list = this.allMenusListSubject.getValue();
            list.push(listMenu[index]);
            this.allMenusListSubject.next(list);
          }
        }
      }

    })
  }

  formatKey(key?: string) {
    if(!key) return '';
    return key == 'triggers' ? TOP_MENU_ENUM.bot_auto : key;
  }

  setMenusList(menus: IMenu[]) {
    this.menusSubject.next(menus);
  }

  setMenuActiveByName(nameMenu?: string) {
    if (!nameMenu) {
    this.setMenuActive(null);
      return
    }
    const menu = this.allMenusListSubject?.getValue()?.find(o => o?.nameMenu == nameMenu);
    this.setMenuActive(menu || null);
  }

  setMenuExtendModule() {
    const menu = this.menusSubject?.getValue()?.find(o => o?.nameMenu == 'extend_menu');
    this.setMenuActive(menu || null);
  }

  setActiveMenuSettings() {
    const menu = this.menusSubject?.getValue()?.find(o => o?.nameMenu == 'settings');
    this.setMenuActive(menu || null);
  }

  setMenuActiveByPath(path?: string) {
    if (!path) return;
    const menu = this.allMenusListSubject?.getValue()?.find(o => path?.includes(o?.router || ''));
    this.setMenuActive(menu || null);
  }

  handelCheckUrlPermission(currentPath?: string, childPath?: string) {
    if (!currentPath) return;
    combineLatest([
      this.bizServices.currentBizSubject,
      this.bizServices.userRolesBizSubject,

    ]).pipe().subscribe(([biz, userRole]) => {
      if (biz && userRole) {
        const isBotAuto = this.bizServices.isPermissionBotAuto();
        const keyRole = KeyMenuSameRole[currentPath];
        const checkUser = userRole && (keyRole in userRole);
        const checkMenuParent = checkUser && (!userRole[keyRole]?.status
          || (userRole[keyRole]?.status && keyRole == 'chatbot' && !isBotAuto));
        if(!checkMenuParent && childPath) {
          const keyRoleChild = KeyMenuSameRole[childPath];
          const checkMenuChild = checkUser && (userRole[keyRole]?.status && !userRole[keyRole][keyRoleChild]);
          if(checkMenuChild) this.router.navigate([`bizs/${biz?.alias}/empty-permissions`]);
        }
        if (checkMenuParent && !childPath) {
          this.router.navigate([`bizs/${biz?.alias}/empty-permissions`]);
        }
      }
    })
  }


  setMenuActive(menu: IMenu | null) {
    this.menuActiveSubject.next(menu);
  }

}
